<script lang="ts">
    import * as Avatar from "$lib/components/ui/avatar";
    import bring from "$lib/images/bring.png";
    import dhl from "$lib/images/dhl.png";
    import helthjem from "$lib/images/helthjem.png";
    import posten from "$lib/images/posten.png";
    import postnord from "$lib/images/postnord.png";
    import schenker from "$lib/images/schenker.png";
    import { cn } from "$src/lib/utils";

    export let carrier: string = "";
    let className = $$props["class"];

    const images: { [key: string]: string } = {
        bring,
        dhl,
        postnord,
        helthjem,
        posten,
        schenker,
    };

    let trimmedCarrier = carrier.replace(/\s/g, "").toLowerCase();
    if (trimmedCarrier === "bring2") {
        trimmedCarrier = "bring";
    }
    const carrierDisplayName =
        trimmedCarrier.charAt(0).toUpperCase() + trimmedCarrier.slice(1);
</script>

<Avatar.Root class={cn(className)}>
    {#if trimmedCarrier in images}
        <Avatar.Image src={images[trimmedCarrier]} alt={carrierDisplayName} />
    {:else}
        <Avatar.Fallback>{carrierDisplayName}</Avatar.Fallback>
    {/if}
</Avatar.Root>
